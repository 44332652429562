import Cookies from "js-cookie";
import { useAppAuthorisationList } from "@gocardless/api/dashboard/app-authorisation";
import { useGetPrimaryCreditorResponse } from "src/queries/creditor";
import { CookieStorage } from "src/common/constants/storage";
import { useI18n } from "src/common/i18n";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { EpochTimestamp } from "src/components/date";
import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";
import { useAccessToken } from "src/common/authorisation";

export function useOptimizelyAttributes() {
  const [locale] = useI18n();

  const { data: creditorResponse, isLoading: creditorLoading } =
    useGetPrimaryCreditorResponse();
  const creditor = creditorResponse?.creditors;
  const [accessToken] = useAccessToken();

  const { data, isLoading: organisationLoading } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );
  const organisation = data?.organisations;
  const { data: appAuthorisations, isLoading: appAuthorisationsLoading } =
    useAppAuthorisationList();
  const { activationDiscount, remainingDays } = useActivationDiscount();

  const cypressFeatureFlag = JSON.parse(
    window.localStorage.getItem("gc.cypress") || "{}"
  );

  const is_eligible_for_pricing = Cookies.get(
    CookieStorage.PricingOptimizelyID || ""
  );

  const organisationCreatedAtTimestamp = EpochTimestamp(
    organisation?.created_at
  );

  const accountAgeInDays = Math.floor(
    (new Date().getTime() -
      new Date(organisation?.created_at as unknown as string).getTime()) /
      (1000 * 3600 * 24)
  );

  // distinguish an undefined value from a null value. Undefined is for when we are still loading the data
  if (organisationLoading || appAuthorisationsLoading || creditorLoading) {
    return undefined;
  }

  // Loading is done, but there is no data, set the attribute to null
  if (!creditor && !organisation && !appAuthorisations) {
    return null;
  }

  const attributes = {
    organisation_id: organisation?.id,
    account_type: organisation?.account_type ?? "",
    connected_app_id:
      appAuthorisations?.app_authorisations?.[0]?.links?.app ?? "",
    signup_app_id: organisation?.links?.signup_app ?? "",
    discount_type: remainingDays ? activationDiscount?.discount_type : "",
    organisation_created_at: organisationCreatedAtTimestamp,
    geo: creditor?.geo ?? "",
    package_state: organisation?.package_state ?? "",
    creditor_type: creditor?.creditor_type ?? "",
    should_pay_for_intelligent_retries:
      organisation?.should_pay_for_intelligent_retries,
    verification_status: creditor?.verification_status ?? "",
    is_eligible_for_share_of_wallet_experiments:
      organisation?.is_eligible_for_share_of_wallet_experiments ?? true,
    locale: locale,
    cypress: cypressFeatureFlag,
    activated: creditor?.activated ?? true,
    screen_width: screen.width,
    fpj_enabled: organisation?.fpj_enabled ?? false,
    referral_scheme_enabled: organisation?.referral_scheme_enabled ?? false,
    is_payment_provider: !!organisation?.links?.payment_provider,
    account_age_in_days: accountAgeInDays,
    is_multi_creditor: organisation?.is_multi_creditor ?? false,
    sdd_enabled: creditor?.sdd_enabled ?? false,
    is_eligible_for_self_serve_account_closure:
      organisation?.is_eligible_for_self_serve_account_closure ?? false,
    cpv_enabled: organisation?.cpv_enabled ?? false,
    is_eligible_for_pricing: Boolean(is_eligible_for_pricing) ?? false,
  };

  return attributes;
}
